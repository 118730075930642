import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { AsYouType, CountryCode, getCountryCallingCode, parsePhoneNumber } from 'libphonenumber-js';
import { Rifm } from 'rifm';
import { Country, getCountries, isValidPhoneNumber } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import { map } from 'lodash';
import { DEFAULT_COUNTRY_CODE_2 } from '@/constants/default';
import { ItemTypeSelect, SelectRC } from '../SelectRC';
import styles from './SelectRCPhoneCountry.module.css';

export interface SelectRCPhoneCountryProps {
  className?: string;
  label?: string;
  size?: 'medium' | 'large';
  onChange: (v: string) => void;
  value: string;
  isNotEmptySelect?: boolean;
  errorMessage?: string;
}

const renderFlag = (country: Country) =>
  flags?.[country || DEFAULT_COUNTRY_CODE_2]?.({
    title: country || DEFAULT_COUNTRY_CODE_2,
  });

export const SelectRCPhoneCountry = ({
  onChange,
  value,
  size = 'large',
  className,
  label,
  isNotEmptySelect,
  errorMessage,
}: SelectRCPhoneCountryProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [selectValue, setSelectValue] = useState<ItemTypeSelect>();
  const renderItem = (country: Country) => {
    return (
      <div className={styles.row}>
        <div className={styles.flag}>{renderFlag(country)}</div>
        <span>{country}</span>
      </div>
    );
  };

  const parseDigits = (v: string) => (v.match(/\+?\d+/g) || []).join('');

  const formatPhone = useCallback(
    (v: string) => {
      const digits = parseDigits(v).substring(0, 15);
      return new AsYouType(selectValue?.value as CountryCode).input(`${digits}`);
    },
    [selectValue?.value],
  );

  useEffect(() => {
    setSelectValue({
      value: 'AU',
      label: renderItem('AU'),
    });
  }, []);

  useEffect(() => {
    if (isValidPhoneNumber(value) && isNotEmptySelect) {
      const { country } = parsePhoneNumber(value);
      setSelectValue({
        value: country || 'AU',
        label: renderItem(country || 'AU'),
      });
    }
  }, [isNotEmptySelect, value]);

  useEffect(() => {
    onChange(`+${getCountryCallingCode((selectValue?.value as CountryCode) || 'AU')}`);
    if (selectValue && isValidPhoneNumber(value)) {
      const { nationalNumber, countryCallingCode } = parsePhoneNumber(value);
      onChange(`+${countryCallingCode}${nationalNumber}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectValue?.value]);

  return (
    <>
      <div
        className={clsx(styles.container, className, {
          [styles.focused]: isFocused,
          [styles.medium]: size === 'medium',
        })}
      >
        {label && (
          <div
            className={clsx(styles.labelWrapper, {
              [styles.labelWrapperMedium]: size === 'medium',
            })}
          >
            <label className={styles.label}>{label}</label>
          </div>
        )}
        <SelectRC
          valueClassName={styles.selectValue}
          withoutRadios
          variant="phoneMedium"
          size={size}
          className={styles.select}
          value={selectValue}
          onChange={setSelectValue}
          options={map(getCountries(), (country) => ({
            value: country,
            label: renderItem(country),
          }))}
        />

        <Rifm accept={/\+?\d+/g} format={formatPhone} value={value} onChange={onChange}>
          {({ value, onChange }) => (
            <div className={styles.inputWrapper}>
              <input
                className={clsx(styles.input, {
                  [styles.inputMedium]: size === 'medium',
                })}
                type="tel"
                value={value}
                onChange={onChange}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
              />
            </div>
          )}
        </Rifm>
        {!!errorMessage && (
          <div className={styles.error}>
            <p className={styles.errorText}>{errorMessage}</p>
          </div>
        )}
      </div>
    </>
  );
};
