import type { AppContext, AppInitialProps, AppProps } from 'next/app';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import type { NextPage } from 'next';
import { ToastContainer } from 'react-toastify';
import Head from 'next/head';
import { QueryClient, QueryClientProvider } from 'react-query';
import '../styles/globals.css';
import { useRoleBasedRouter } from '@/hooks';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { Routes } from '@/constants/routes';
import 'react-toastify/dist/ReactToastify.css';
import { useWebsiteRedirect } from '@/store/websiteRedirect';
import { shallow } from 'zustand/shallow';
import packageInfos from '../package.json';

import { RouterSuspense } from '../components/common';

datadogRum.init({
  applicationId: '389d1f81-bbbb-42e5-adf3-10f905c0f2a6',
  clientToken: 'pub419dacb81d922478e330b8408022fb04',
  site: 'datadoghq.com',
  service: packageInfos.name,
  version: process.env.NEXT_PUBLIC_APP_VERSION || packageInfos.version,
  env: process.env.NEXT_PUBLIC_ENVIRONMENT,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'allow',
});
datadogRum.setGlobalContext({
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  version: process.env.NEXT_PUBLIC_APP_VERSION,
});

datadogLogs.init({
  clientToken: 'pub419dacb81d922478e330b8408022fb04',
  site: 'datadoghq.com',
  service: packageInfos.name,
  version: process.env.NEXT_PUBLIC_APP_VERSION || packageInfos.version,
  env: process.env.NEXT_PUBLIC_ENVIRONMENT,
  forwardErrorsToLogs: false,
  sessionSampleRate: 100,
});
datadogLogs.setGlobalContext({
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  version: process.env.NEXT_PUBLIC_APP_VERSION,
});

export type NextPageWithLayout<P = Record<never, never>, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { retry: false, refetchOnMount: false, refetchOnWindowFocus: false },
    mutations: { retry: false },
  },
});

function MyApp(props: AppPropsWithLayout) {
  const { Component, pageProps } = props || {};
  const websiteRedirect = useWebsiteRedirect((state) => state, shallow);

  const getLayout = Component?.getLayout || ((page) => page);

  const router = useRouter();
  const { allowed } = useRoleBasedRouter();

  const shouldLoadZoho = router.pathname !== Routes.WIDGET && router.pathname !== Routes.WIDGET_GENERATOR;

  let { title, description, image } = pageProps;

  if (!title) {
    title = 'Crypto Payment Solutions – Buy, sell or spend your crypto | RelayPay';
  }

  if (!description) {
    description = 'Buy, sell or spend cryptocurrency at global exchange rates. Sign up for RelayPay today!';
  }

  if (!image) {
    image = 'index';
  }

  const SITE_URL = process.env.NEXT_PUBLIC_WEB_APP_URL;

  let url = SITE_URL;
  if (router.pathname !== '/') {
    url += router.asPath;
  }

  useEffect(() => {
    websiteRedirect.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Head>
          <title>Relaypay</title>
          <meta name="description" content="Relaypay Web App" />
          <meta name="referrer" content="unsafe-url" />
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />

          {/* Favicon */}
          <link rel="apple-touch-icon" sizes="57x57" href="/favicon/apple-icon-57x57.png" />
          <link rel="apple-touch-icon" sizes="60x60" href="/favicon/apple-icon-60x60.png" />
          <link rel="apple-touch-icon" sizes="72x72" href="/favicon/apple-icon-72x72.png" />
          <link rel="apple-touch-icon" sizes="76x76" href="/favicon/apple-icon-76x76.png" />
          <link rel="apple-touch-icon" sizes="114x114" href="/favicon/apple-icon-114x114.png" />
          <link rel="apple-touch-icon" sizes="120x120" href="/favicon/apple-icon-120x120.png" />
          <link rel="apple-touch-icon" sizes="144x144" href="/favicon/apple-icon-144x144.png" />
          <link rel="apple-touch-icon" sizes="152x152" href="/favicon/apple-icon-152x152.png" />
          <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-icon-180x180.png" />
          <link rel="icon" type="image/png" sizes="192x192" href="/favicon/android-icon-192x192.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="96x96" href="/favicon/favicon-96x96.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
          <link rel="manifest" href="/favicon/manifest.json" />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta name="msapplication-TileImage" content="/favicon/ms-icon-144x144.png" />
          <meta name="theme-color" content="#ffffff" />
        </Head>

        <NextSeo
          title={title}
          description={description}
          canonical={url}
          openGraph={{
            title,
            description,
            url,
            type: 'website',
            site_name: 'RelayPay',
            images: [
              {
                url: `${SITE_URL}/assets/meta/${image}.png`,
                width: 1280,
                height: 720,
                alt: 'RelayPay Image',
              },
            ],
          }}
        />
        <RouterSuspense isReady={allowed} fallback={null}>
          {getLayout(
            <>
              <ToastContainer />
              <Component {...pageProps} />
            </>,
          )}
        </RouterSuspense>
      </QueryClientProvider>
      {/* Zoho chat widget */}
      {shouldLoadZoho && (
        <script
          dangerouslySetInnerHTML={{
            __html: `
                var $zoho = $zoho || {};
                $zoho.salesiq = $zoho.salesiq || {
                  widgetcode: "a54b19a2193fe1ef02945568a97b3549e07fcc71b199de52e479bc32a1633fa6",
                  values: {},
                  ready: function () {}
                };
                var d = document;
                var s = d.createElement("script");
                s.type = "text/javascript";
                s.id = "zsiqscript";
                s.defer = true;
                s.src = "https://salesiq.zoho.com.au/widget";
                var t = d.getElementsByTagName("script")[0];
                t.parentNode.insertBefore(s, t);
                d.write("<div id='zsiqwidget'></div>");
              `,
          }}
        />
      )}
      {/* End Zoho chat widget code */}
    </>
  );
}

export default MyApp;
