import clsx from 'clsx';
import React, { Key, useContext, useRef } from 'react';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useRadio, useRadioGroup } from '@react-aria/radio';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { useRadioGroupState } from '@react-stately/radio';
import { CryptoCurrency, FiatCurrency } from '@/api/common.types';
import { CoinNetworksResponse } from '@/api';
import { getCoinIcon } from '@/utils/getCoinIcon';
import { onEnterPressRadio } from '../../../../utils/events';
import styles from './RadioSelectCurrency.module.css';
import { RadioContext, RadioGroupProps, RadioProps } from '../Radio/Radio';
import { IconType } from '../../Icon';
import { SelectListItem } from '../SelectList';
import { Select } from '../Select';
import { Spinner } from '../../Spinner';

export const RadioSelectCurrencyGroup = ({ className, ariaLabel, ...props }: RadioGroupProps) => {
  const { children } = props;
  const state = useRadioGroupState(props);
  const { radioGroupProps } = useRadioGroup({ ...props, 'aria-label': ariaLabel }, state);

  return (
    <div {...radioGroupProps} className={clsx(styles.group, className)}>
      <RadioContext.Provider value={state}>{children}</RadioContext.Provider>
    </div>
  );
};

export interface RadioSelectCurrencyProps {
  name?: string;
  nameShort?: string;
  balanceValue?: string | null;
  balanceCurrency?: string;
  currencyEqual?: string | null;
  fiatCurrency?: FiatCurrency;
  icon: IconType;
  selectedCoinNetworksData: CoinNetworksResponse[];
  isNetworksLoading?: boolean;
  onNetworkSelect: (arg: CryptoCurrency) => void;
  selectedCoin?: string;
  selectedNetwork?: CryptoCurrency | null;
}

export const RadioSelectCurrency = ({
  className,
  contentClassName,
  name,
  nameShort,
  balanceValue,
  balanceCurrency,
  currencyEqual,
  icon,
  fiatCurrency,
  selectedCoinNetworksData,
  isNetworksLoading,
  onNetworkSelect,
  selectedCoin,
  selectedNetwork,
  ...props
}: RadioProps & RadioSelectCurrencyProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const state = useContext(RadioContext);
  const { inputProps } = useRadio(
    {
      ...props,
      onKeyUp: onEnterPressRadio(props, state, props.value),
    },
    state,
    ref,
  );

  const { focusProps, isFocusVisible: isFocused } = useFocusRing();
  const isChecked = !!inputProps.checked;
  return (
    <label
      className={clsx(styles.wrapper, className, {
        [styles.wrapperChecked]: isChecked,
      })}
    >
      <VisuallyHidden>
        <input {...mergeProps(inputProps, focusProps)} className={styles.input} ref={ref} type="radio" />
      </VisuallyHidden>
      <div className={clsx(styles.inner, contentClassName)}>
        <div className={styles.title}>
          <div className={styles.flexBox}>
            <div className={styles.icon}>{getCoinIcon(nameShort ?? '')}</div>
            <span className={styles.name}>{name}</span>
          </div>
          <div className={styles.flexBox}>
            <span className={styles.balance}>
              {balanceValue} {nameShort}
            </span>
            <div
              className={clsx(styles.radio, {
                [styles.checked]: isChecked,
                [styles.focused]: isFocused,
              })}
            >
              {isChecked && (
                <span
                  className={clsx(styles.radioDot, {
                    [styles.checkedRadioDot]: isChecked,
                    [styles.focused]: isFocused,
                  })}
                />
              )}
            </div>
          </div>
        </div>
        <div className={styles.description}>
          <div>Price:</div>
          <div>
            1 {nameShort} = ${currencyEqual} {balanceCurrency} {fiatCurrency ?? 'AUD'}
          </div>
        </div>
        {selectedCoin && selectedCoin?.length > 0 && selectedCoin === nameShort && (
          <>
            <div className={styles.divider} />
            {isNetworksLoading ? (
              <Spinner side={56} />
            ) : (
              <Select
                rightIcon={
                  <div className={styles.selectRightIconBox}>
                    {selectedNetwork}
                    <div className={styles.selectRightIcon}>{getCoinIcon(selectedNetwork ?? '')}</div>
                  </div>
                }
                inputClassName={styles.networkSelectInput}
                defaultSelectedKey={selectedNetwork as Key}
                className={styles.networkSelect}
                labelClassName={styles.label}
                label="CHOOSE NETWORK"
                onSelectionChange={(e) => {
                  onNetworkSelect(e as CryptoCurrency);
                }}
                filterFn={() => true}
                isShowMore
              >
                {selectedCoinNetworksData?.map((item) => {
                  return (
                    <SelectListItem key={item?.cryptoNetwork} textValue={item?.description}>
                      <div className={styles.networkSelectItem}>
                        <div className={styles.networkSelectIcon}>{getCoinIcon(item?.cryptoNetwork)}</div>
                        <div className={styles.networkSelectContent}>
                          <div className={styles.networkSelectLabel}>{item?.cryptoNetwork}</div>
                          <div className={styles.networkSelectDescription}>{item?.description}</div>
                        </div>
                      </div>
                    </SelectListItem>
                  );
                })}
              </Select>
            )}
          </>
        )}
      </div>
    </label>
  );
};

export default RadioSelectCurrency;
