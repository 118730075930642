import BTC from 'cryptocurrency-icons/svg/color/btc.svg';
import ETH from 'cryptocurrency-icons/svg/color/eth.svg';
import USDT from 'cryptocurrency-icons/svg/color/usdt.svg';

import BNB from '../public/assets/bnb-logo.svg';
import DAI from '../public/assets/dai-logo.svg';
import MATIC from '../public/assets/matic-logo.svg';
import LINK from '../public/assets/link-logo.svg';
import TUSD from '../public/assets/tusd-logo.svg';
import UNI from '../public/assets/uni-logo.svg';
import WBTC from '../public/assets/wbtc-logo.svg';
import APE from '../public/assets/ape-logo.svg';
import USDC from '../public/assets/usdc-logo.svg';
import ARB from '../public/assets/arb-logo.svg';
import OP from '../public/assets/op-logo.svg';
import SHIB from '../public/assets/shib-logo.svg';
import SOL from '../public/assets/sol-logo.svg';
import AVAX from '../public/assets/avax-logo.svg';
import TRX from '../public/assets/trx-logo.svg';
import XRP from '../public/assets/xrp-logo.svg';
import XLM from '../public/assets/stellar-xlm-logo.svg';
import BSC from '../public/assets/bsc-logo.svg';
import BASE from '../public/assets/base-logo.svg';
import ARBITRUM from '../public/assets/arbitrum-ogo.svg';
import STARKNET from '../public/assets/starknet-logo.svg';
import MANTA from '../public/assets/manta-logo.svg';
import ZKSYNCERA from '../public/assets/zksync-logo.svg';
import NEAR from '../public/assets/near-logo.svg';
import CLEO from '../public/assets/cleo-logo.svg';
import RON from '../public/assets/ronin-logo.svg';
import XTZ from '../public/assets/xtz-logo.svg';
import CELO from '../public/assets/celo-logo.svg';
import EOS from '../public/assets/eos-logo.svg';
import DOGE from '../public/assets/DOGE.svg';
import LTC from '../public/assets/LTC.svg';

export function getCoinIcon(symbol: string) {
  switch (symbol) {
    case 'BTC': {
      return <BTC />;
    }
    case 'ETH': {
      return <ETH />;
    }
    case 'USDT': {
      return <USDT />;
    }
    case 'USDC': {
      return <USDC />;
    }
    case 'BNB': {
      return <BNB />;
    }
    case 'TUSD': {
      return <TUSD />;
    }
    case 'DAI': {
      return <DAI />;
    }
    case 'LINK': {
      return <LINK />;
    }
    case 'MATIC': {
      return <MATIC />;
    }
    case 'ARB': {
      return <ARB />;
    }
    case 'OP': {
      return <OP />;
    }
    case 'UNI': {
      return <UNI />;
    }
    case 'WBTC': {
      return <WBTC />;
    }
    case 'SHIB': {
      return <SHIB />;
    }
    case 'APE': {
      return <APE />;
    }
    case 'SOL': {
      return <SOL />;
    }
    case 'AVAX': {
      return <AVAX />;
    }
    case 'AVAXC': {
      return <AVAX />;
    }
    case 'TRX': {
      return <TRX />;
    }
    case 'XRP': {
      return <XRP />;
    }
    case 'XLM': {
      return <XLM />;
    }
    case 'BSC': {
      return <BSC />;
    }
    case 'BASE': {
      return <BASE />;
    }
    case 'ARBITRUM': {
      return <ARBITRUM />;
    }
    case 'OPTIMISM': {
      return <OP />;
    }
    case 'STARKNET': {
      return <STARKNET />;
    }
    case 'STRK': {
      return <STARKNET />;
    }
    case 'MANTA': {
      return <MANTA />;
    }
    case 'ZKSYNCERA': {
      return <ZKSYNCERA />;
    }
    case 'ZKSYNC': {
      return <ZKSYNCERA />;
    }
    case 'zkSync': {
      return <ZKSYNCERA />;
    }
    case 'NEAR': {
      return <NEAR />;
    }
    case 'CLEO': {
      return <CLEO />;
    }
    case 'RON': {
      return <RON />;
    }
    case 'XTZ': {
      return <XTZ />;
    }
    case 'CELO': {
      return <CELO />;
    }
    case 'EOS': {
      return <EOS />;
    }
    case 'LTC': {
      return <LTC />;
    }
    case 'DOGE': {
      return <DOGE />;
    }

    default: {
      return null;
    }
  }
}
